@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../../../public/fonts/Roboto-Black.ttf') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../../public/fonts/Roboto-Bold.ttf') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../../public/fonts/Roboto-Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../../public/fonts/Roboto-Light.ttf') format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../../public/fonts/Roboto-Medium.ttf') format('woff2');
}