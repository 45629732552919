@forward "common/libs";
@forward "common/fonts/fonts";

// :root {
//   --main: #333;
//   --main-rgb: 51,51,51;
//   color-scheme: light;
// }

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #172B4D;



  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
  }
}


h1 {
  font-size: 35px;
  letter-spacing: -0.24px;
}
h2 {
  font-size: 29px;
  letter-spacing: -0.24px;
}
h3 {
  font-size: 24px;
  letter-spacing: -0.06px;
}
h4 {
  font-size: 20px;
  letter-spacing: -0.05px;
}
h5 {
  font-size: 16px;
  letter-spacing: -0.05px;
}
h6 {
  font-size: 14px;
  letter-spacing:  -0.05px;
}

.hello {
  font-size: 50px;
  color: orange;
}

// .dark {
//   --main: #fff;
//   --main-rgb: 255,255,255;
//   color-scheme: dark;
// }