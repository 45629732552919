@use '../../../app/styles/common/var';

.root {
  min-height: 100svh;
  height: 100%;
  width: 100%;
  position: relative;
}

.contentWrapper {
  padding-top: 64px;
  padding-left: 255px;
  width: 100%;
  height: 100%;
  background-color: var.$bg-content;
  min-height: 100svh;
  transition: all 0.4s ease;
  @media (max-width: 1200px) {
    padding-left: 0px;
    transition: all 0.4s ease;
  }
}

.contentNoPadding {
  padding-left: 0px;
}

.content {
  padding: var.$padding_block_max;
  @media (max-width: 1600px) {
    padding: var.$padding_block_lg;
  }
  @media (max-width: 1200px) {
    padding: var.$padding_block_md;
  }
  @media (max-width: 768px) {
    padding: var.$padding_block_tablet;
  }
  @media (max-width: 500px) {
    padding: var.$padding_block_mobile;
  }
}