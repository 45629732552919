@use '../../../app/styles/common/var';

.root {
  position: fixed;
  padding-top: 64px;
  width: 255px;
  height: 100%;
  transition: transform 0.4s ease;
  background-color: white;
  z-index: 0;
  border-right: 1px solid var.$border-card;;
  @media (max-width: 1200px) {
    transition: transform 0.4s ease;
    transform: translateX(-255px);
    padding-top: 0px;
    z-index: 101;
  }
}

.mobileSideOpen {
  transform: translateX(0px);
}

.shadowContent {
  background-color: #00000080;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  @media (min-width: 1200px) {
    display: none;
  }
}

.asideHeader {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var.$border-card;
}
.asideHeaderContent {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
}


.menuList {
  padding: 8px 16px;
  border-bottom: 1px solid var.$border-card;
}

.systemInfo {
  font-size: 14px;
  padding-top: 20px;
  text-align: center;
}
