.root {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.301);
  border-radius: 10px;
  padding: 15px 16px;

  @media (max-width: 1600px) {

  }
  @media (max-width: 1400px) {

  }
  @media (max-width: 1200px) {

  }
}

.emptyResurseText {
  text-align: center;
  color: #6c6c6c;
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 1600px) {
    font-size: 15.75px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
  }
}